.m-input {
  &.p-inputgroup {
    border-color: theme("colors.gray-500");

    .p-inputgroup-addon {
      background: theme("colors.gray-000");
      color: theme("colors.blue-600");
      border-color: theme("colors.gray-500");
    }
  }

  &.p-inputtext {
    box-shadow: inset 0 0 0 1px theme("colors.gray-500");
    border: none;
    border-radius: 0.25rem;
    height: 2rem;
    width: inherit;
    padding: 0.5rem 0.75rem;
    color: theme("colors.gray-1000");
  }

  &.p-inputtext:hover {
    background: theme("colors.gray-000");
    box-shadow: inset 0 0 0 1px theme("colors.gray-500");
    border: none;
  }

  &.p-inputtext:focus {
    box-shadow: inset 0 0 0 2px theme("colors.blue-600");
    border: none;
  }

  &.ng-invalid.ng-touched {
    background: theme("colors.red-100");
    box-shadow: inset 0 0 0 2px theme("colors.red-700");
    border: none;
    border-radius: 0.25rem;
  }

  &::placeholder {
    @apply b1 text-gray-600;
  }

  textarea.p-inputtextarea {
    box-shadow: inset 0 0 0 2px theme("colors.gray-500");
    border: none;
    border-radius: 0.25rem;
    min-height: 2rem;
    width: inherit;
  }

  textarea.p-inputtextarea:focus {
    box-shadow: inset 0 0 0 2px theme("colors.blue-600");
    border: none;
  }

  textarea.ng-invalid.ng-touched {
    background: theme("colors.red-100");
    box-shadow: inset 0 0 0 2px theme("colors.red-700");
    border: none;
    border-radius: 0.25rem;
  }

  small.p-error {
    @apply c1 font-medium;

    color: theme("colors.red-700");
  }

  small.p-success {
    @apply c1 font-medium;

    color: theme("colors.green-700");
  }

  &.input-search {
    &.p-inputtext {
      padding: 0.5rem 3.5rem 0.5rem 0.75rem;
    }
  }
}
