// stylelint-disable scss/dollar-variable-default
@import "node_modules/bootstrap/scss/functions";

$white: #fff;
$gray-90: #e5e5e5;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dbdcdd; // Changed
$gray-400: #ced4da;
$gray-500: #adb3ba; // Changed
$gray-600: #6c757d;
$gray-700: #4a5159; // Changed
$gray-800: #31383f; // Changed
$gray-850: #494f57; // Added
$gray-900: #212529;
$black: #000;

//#31383F nav bg
//#494F57 nav item bg

$blue: #4872ac; // Changed
$sapphire: #5a94d8 !default; // Added
$indigo: #6610f2;
$purple: #6f42c1;
$rose: #bd5091 !default; // Added
$pink: #ef5675; // Modified
$red: #e55c5c; // Modified
$orange: #ff764a; // Modified
$yellow: #ffa600; // Modified
$green: #96c670; // Modified
$teal: #20c997;
$cyan: #a2c7e9; // Changed
$benchmarks-blue: #3e70b5;
$benchmarks-light-gray: #617087;
$benchmarks-dark-gray: #354358;
$benchmarks-orange: #e66b43;

// scss-docs-start colors-map
$colors: (
  "blue": $blue,
  "sapphire": $sapphire,
  "indigo": $indigo,
  "purple": $purple,
  "rose": $rose,
  "pink": $pink,
  "red": $red,
  "orange": $orange,
  "yellow": $yellow,
  "green": $green,
  "teal": $teal,
  "cyan": $cyan,
  "white": $white,
  "gray": $gray-600,
  "gray-dark": $gray-800,
);
// scss-docs-end colors-map

$primary: $orange;
$secondary: $blue;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-300;
$dark: $gray-800;

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio: 2;

$sapphire-100: tint-color($sapphire, 80%) !default;
$sapphire-200: tint-color($sapphire, 60%) !default;
$sapphire-300: tint-color($sapphire, 40%) !default;
$sapphire-400: tint-color($sapphire, 20%) !default;
$sapphire-500: $sapphire !default;
$sapphire-600: shade-color($sapphire, 20%) !default;
$sapphire-700: shade-color($sapphire, 40%) !default;
$sapphire-800: shade-color($sapphire, 60%) !default;
$sapphire-900: shade-color($sapphire, 80%) !default;

$rose-100: tint-color($rose, 80%) !default;
$rose-200: tint-color($rose, 60%) !default;
$rose-300: tint-color($rose, 40%) !default;
$rose-400: tint-color($rose, 20%) !default;
$rose-500: $rose !default;
$rose-600: shade-color($rose, 20%) !default;
$rose-700: shade-color($rose, 40%) !default;
$rose-800: shade-color($rose, 60%) !default;
$rose-900: shade-color($rose, 80%) !default;

$orange-400: tint-color($orange, 20%) !default;
$orange-500: $orange !default;
$orange-600: shade-color($orange, 20%) !default;

$yellow-500: $yellow !default;
$yellow-600: shade-color($yellow, 20%) !default;

$green-500: $green !default;
$green-600: shade-color($green, 20%) !default;
$green-700: shade-color($green, 40%) !default;

$primary-color: $orange; // DONE
$secondary-color: $secondary; // DONE
$info-bg: $info; // DONE

$card-border-color: $gray-300; // DONE
$card-button-bg: $secondary; // Make the same as secondary DONE
$active: $white; // DONE
$inactive: darken($active, 25); // DONE

$nav-bg: $gray-800;
$nav-item-bg: $gray-850;
$nav-item-active-bg: $gray-600;
$logo-gradient-stop-1: $yellow; // DONE
$logo-gradient-stop-2: $red; // DONE

// Font-family overrides
$font-family-sans-serif:
  "Inter", -apple-system, "BlinkMacSystemFont",
  "Segoe UI", "Helvetica Neue", "Arial", "Noto Sans", sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-monospace:
  "SFMono-Regular", "Menlo", "Monaco", "Consolas",
  "Liberation Mono", "Courier New", monospace;

// Manipulate header font sizing
$font-size-base: 1rem;

$font-size-sm: $font-size-base * .8 !default;
$font-size-xs: $font-size-base * .625 !default;
$h5-font-size: $font-size-base * 1.15;
$headings-font-weight: 300;
$benchmarks-datagrid-font-size: .875rem;
$benchmarks-datagrid-header-font-weight: 600;
$benchmarks-datagrid-font-weight: 500;
$benchmarks-datagrid-border-color: #edf0f2;
$benchmarks-border-color: #e3e7eb;
$benchmarks-background-color: #f7f9fa;

// Card style overrides
// Used in .card-body
$card-spacer-x: .75rem;
// Used in .card-title for the bottom margin
$card-spacer-y: .35rem;

$teal-600: #1aa179;

$red-200: #f5bebe;

$fw-boldish: 600;
