// Tooltip Variables
// ==================

$tooltip-border-width: 0.375rem; // Border width for tooltip arrows
$tooltip-background: theme("colors.gray-1000"); // Tooltip background color
$tooltip-arrow-offset: calc(
  50% - $tooltip-border-width
); // Arrow centering offset
$tooltip-min-height: 3rem; // Minimum height of tooltips
$tooltip-padding: 1rem 1.5rem; // Padding inside tooltips

// Tooltip Styles for PrimeNG Components
// ======================================
.m-tooltip {
  max-width: initial !important;
  padding: 0 $tooltip-border-width !important;
  text-align: center;

  .p-tooltip-text {
    @apply b1 text-gray-000;
    background-color: $tooltip-background !important;
    border-radius: 4px;
    min-height: $tooltip-min-height !important;
    padding: $tooltip-padding !important;
    white-space: normal !important;
  }

  // Arrow Adjustments
  &.p-tooltip-right .p-tooltip-arrow {
    border-width: $tooltip-border-width $tooltip-border-width
      $tooltip-border-width 0 !important;
    border-color: transparent $tooltip-background transparent transparent !important;
    top: calc(50% - 0.175rem) !important;
  }

  &.p-tooltip-top .p-tooltip-arrow {
    border-width: $tooltip-border-width $tooltip-border-width 0 !important;
    border-color: $tooltip-background transparent transparent transparent !important;
    top: 100% !important;
    left: $tooltip-arrow-offset !important;
  }

  // Arrow Position Adjustments
  &.p-tooltip-top.left .p-tooltip-arrow,
  &.p-tooltip-bottom.left .p-tooltip-arrow {
    left: calc(20% - $tooltip-border-width) !important;
  }

  &.p-tooltip-top.right .p-tooltip-arrow,
  &.p-tooltip-bottom.right .p-tooltip-arrow {
    left: calc(80% - $tooltip-border-width) !important;
  }

  &.p-tooltip-bottom .p-tooltip-arrow {
    border-width: 0 $tooltip-border-width $tooltip-border-width !important;
    border-color: transparent transparent $tooltip-background transparent !important;
    top: -$tooltip-border-width !important;
    left: $tooltip-arrow-offset !important;
  }

  &.p-tooltip-left .p-tooltip-arrow {
    border-width: $tooltip-border-width 0 $tooltip-border-width
      $tooltip-border-width !important;
    border-color: transparent transparent transparent $tooltip-background !important;
    top: calc(50% - 0.175rem) !important;
  }
}

// HighCharts Tooltip Styles
// =========================

.highcharts-tooltip {
  background-color: $tooltip-background !important;

  .highcharts-label-box.highcharts-tooltip-box {
    background-color: $tooltip-background !important;
    fill: $tooltip-background !important;
    stroke: $tooltip-background !important;
    stroke-width: 1 !important;
  }
}

.tooltip-body {
  background-color: $tooltip-background;
  min-height: $tooltip-min-height !important;
  min-width: 10.9375rem !important;
  padding: 0.5rem 1rem !important;
}
