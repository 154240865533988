.m-tabview {
  .p-tabview .p-tabview-nav {
    border-bottom: 0;
    gap: 2rem;

    li {
      margin-right: 0;

      &:not(.p-tabview-ink-bar) {
        display: flex;
      }

      .p-highlight {
        background: transparent;
        border-bottom-color: theme("colors.orange-300");
      }

      &:hover .p-tabview-nav-link:not([aria-selected="true"]) {
        color: theme("colors.gray-800") !important;
        background: transparent !important;
        border: none !important;
        border-bottom: 2px solid theme("colors.gray-400") !important;
      }

      .p-tabview-nav-link {
        color: theme("colors.gray-800");
        background: transparent;
        border: none;
        border-bottom: 2px solid transparent;
        margin: 0;
        padding: 0;
        padding-bottom: 0.5rem;

        .saas-tab-header {
          color: theme("colors.gray-800");
        }
      }

      .p-tabview-nav-link[aria-selected="true"] {
        color: theme("colors.gray-1000");
        border-bottom-color: theme("colors.orange-300");

        .saas-tab-header {
          color: theme("colors.gray-1000");
        }
      }

      .p-tabview-nav-link:focus {
        box-shadow: none !important;
      }
    }
  }

  .p-tabview .p-tabview-panels {
    padding: 0;
    margin-top: 1rem;

    .p-tabview-panel {
      padding: 0;
    }
  }

  &.contained {
    .m-tabview-header {
      background-color: theme("colors.gray-100");
      border: 1px solid theme("colors.gray-500");
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      border-bottom: none;
    }

    .p-tabview-panels {
      background-color: theme("colors.gray-000");
      border: 0 none;
      margin: 2rem;
    }

    .p-tabview {
      .p-tabview-nav {
        border-bottom: 1px solid theme("colors.gray-500");
        grid-gap: 10px;
        padding-left: 25px;

        li {
          .p-tabview-nav-link {
            padding: 0;
            border: none;

            &:hover {
              border: none !important;
            }

            &:not(.p-disabled):focus {
              box-shadow: none;
            }
          }
        }
      }

      .p-highlight {
        .p-tabview-nav-link {
          border-bottom: 1px solid theme("colors.gray-000");
          margin-bottom: -1px;
        }

        .m-tabview-header {
          background-color: theme("colors.gray-000");
        }
      }
    }
  }
}
