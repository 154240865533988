// TODO: Create a TypeScript dictionary/enum for brand names/icons as a single source of truth instead of using assets and class names.
/* stylelint-disable selector-class-pattern */
.adwords-icon,
.attentive-icon,
.amazon-icon,
.applovin-icon,
.bell-icon,
.bidtellect-icon,
.bing-ads-icon,
.bing-icon,
.bouncex-icon,
.cj-icon,
.connected-icon,
.criteo-icon,
.dbm-icon,
.default-datasource-icon,
.download-icon,
.email-icon,
.facebook-icon,
.ga-icon,
.ga4-icon,
.google-ads-icon,
.google-analytics-icon,
.google-campaign-manager-360-icon,
.google-display-\&-video-360-icon,
.handyman-icon,
.iheartmedia-icon,
.impact-icon,
.impactTransaction-icon,
.klaviyo-icon,
.measured-icon,
.measured-login-icon,
.measured-logo-text-white-icon,
.measured-logo-text-white-m-fade-icon,
.measured-logo-white-icon,
.measured-m-icon,
.measured-m-white-icon,
.no-data-source-icon,
.pepperjam-icon,
.pinterest-icon,
.proprietarysot-icon,
.reddit-icon,
.revenue-icon,
.shareasale-icon,
.shopify-icon,
.snapchat-icon,
.steelhouse-icon,
.taxes-icon,
.tiktok-icon,
.tradedesk-icon,
.twitter-icon {
  width: 1.5rem;
  height: 1.5rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  .material-symbols-outlined {
    display: none;
  }
}

.adwords-icon {
  background-image: url("/assets/images/integrations/adwords-logo.svg");
}

.attentive-icon {
  background-image: url("/assets/images/integrations/attentive-logo.png");
}

.amazon-icon {
  background-image: url("/assets/images/integrations/amazon-logo.svg");
}

.applovin-icon {
  background-image: url("/assets/images/integrations/applovin-logo.png");
}

.bell-icon {
  background-image: url("/assets/images/integrations/bell.svg");
}

.bidtellect-icon {
  background-image: url("/assets/images/integrations/bidtellect-logo.svg");
}

.bing-ads-icon,
.bing-icon {
  background-image: url("/assets/icons/bing.svg");
}

.bouncex-icon {
  background-image: url("/assets/images/integrations/bouncex-logo.svg");
}

.cj-icon {
  background-image: url("/assets/images/integrations/cj-logo.svg");
}

.connected-icon {
  background-image: url("/assets/images/integrations/connected.png");
}

.criteo-icon {
  background-image: url("/assets/images/integrations/criteo-logo.svg");
}

.dbm-icon {
  background-image: url("/assets/images/integrations/dbm-logo.png");
}

.default-datasource-icon {
  background-image: url("/assets/images/integrations/default-datasource.svg");
}

.download-icon {
  background-image: url("/assets/images/integrations/download.svg");
}

.email-icon {
  background-image: url("/assets/images/integrations/email.svg");
}

.facebook-icon {
  background-image: url("/assets/images/integrations/facebook-logo.png");
}

.ga-icon,
.ga4-icon {
  background-image: url("/assets/images/integrations/ga-logo.svg");
}

.google-ads-icon {
  background-image: url("/assets/images/integrations/google-ads-logo.svg");
}

.google-analytics-icon {
  background-image: url("/assets/images/integrations/google-analytics-logo.svg");
}

.google-campaign-manager-360-icon {
  background-image: url("/assets/images/integrations/google-campaign-manager-360-logo.png");
}

.google-display-\&-video-360-icon {
  background-image: url("/assets/images/integrations/google-display-&-video-360-logo.png");
}

.handyman-icon {
  background-image: url("/assets/images/integrations/handyman-icon.svg");
}

.iheartmedia-icon {
  background-image: url("/assets/images/integrations/iheartmedia-logo.svg");
}

.impact-icon,
.impactTransaction-icon {
  background-image: url("/assets/images/integrations/impact-logo.png");
}

.klaviyo-icon {
  background-image: url("/assets/images/integrations/klaviyo-logo.png");
}


.measured-icon {
  background-image: url("/assets/images/integrations/measured-logo.svg");
}

.measured-login-icon {
  background-image: url("/assets/images/integrations/measured-login-logo.svg");
}

.measured-logo-text-white-icon {
  background-image: url("/assets/images/integrations/measured-logo-text-white.svg");
}

.measured-logo-text-white-m-fade-icon {
  background-image: url("/assets/images/integrations/measured-logo-text-white-m-fade.svg");
}

.measured-logo-white-icon {
  background-image: url("/assets/images/integrations/measured-logo-white.svg");
}

.measured-m-icon {
  background-image: url("/assets/images/integrations/measured-m.svg");
}

.measured-m-white-icon {
  background-image: url("/assets/images/integrations/measured-m-white.svg");
}

.no-data-source-icon {
  background-image: url("/assets/images/integrations/integrations-no-data-source.svg");
}

.pepperjam-icon {
  background-image: url("/assets/images/integrations/pepperjam-logo.png");
}

.pinterest-icon {
  background-image: url("/assets/images/integrations/pinterest-logo.svg");
}

.proprietarysot-icon {
  background-image: url("/assets/images/integrations/proprietarysot.svg");
}

.reddit-icon {
  background-image: url("/assets/images/integrations/reddit-logo.png");
}

.revenue-icon {
  background-image: url("/assets/images/revenue.svg");
}

.shareasale-icon {
  background-image: url("/assets/images/integrations/shareasale-logo.png");
}

.shopify-icon {
  background-image: url("/assets/images/integrations/shopify_glyph.png");
}

.snapchat-icon {
  background-image: url("/assets/images/integrations/snapchat-logo.png");
}

.steelhouse-icon {
  background-image: url("/assets/images/integrations/mntn-logo.png");
}

.taxes-icon {
  background-image: url("/assets/images/taxes.svg");
}

.tiktok-icon {
  background-image: url("/assets/images/integrations/tiktok-logo.png");
}

.tradedesk-icon {
  background-image: url("/assets/images/integrations/tradedesk-logo.svg");
}

.twitter-icon {
  background-image: url("/assets/images/integrations/twitter.svg");
}
