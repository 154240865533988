@import "node_modules/bootstrap/scss/functions";

$green-700: #96C670;
$green-600: #60C99D;
$green-800: #4DA17E;

$red-100: #faeeee;
$red-200: #f5bebe;
$red-400: #e09797;
$red-500: #D67575;
$red-600: #CC5252;
$red-700: #BF3939;

$orange-10: #ecc4b2;
$orange-100: #F1A47A;
$orange-200: #e9865d;

$white: #fff;
$gray-90: #e5e5e5;
$gray-100: #F7F9FA;
$gray-150: #f2f4f5;
$gray-200: #EDF0F2;
$gray-300: #E3E7EB;
$gray-350: #dee2e6;
$gray-400: #ced4da;
$gray-450: #c9cfd8;
$gray-500: #adb3ba;
$gray-600: #93A0AF;
$gray-700: #4a5159;
$gray-800: #31383f;
$gray-850: #494f57;
$gray-900: #212529;
$black: #000;

$blue-100: #eaf0f9;
$blue-200: #d6e2f2;
$blue-300: #acc4e5;
$blue-400: #83a7d9;
$blue-500: #5989cc;
$blue-600: #3e70b5;
$blue-700: #265699;
$blue-800: #1d4173;
$blue-900: #132b4c;

$light-yellow: #FFF4E4;

$saas-blue: #3e70b5;
$saas-dark-blue: #265699;
$saas-border-color: #edf0f2;
$saas-background-color: #f7f9fa;
$saas-background-hover-color: #edf0f2;
$saas-light-background-color: #eaf0f9;
$saas-light-gray-border: #e3e7eb;
$saas-light-gray: #aeb7c3;
$saas-dark-gray: #354358;
$saas-gray: #617087;
$saas-orange: #e66b43;
$saas-dark: #0a1626;
$saas-semi-dark: #1b2b41;
$saas-darker-border: #4a5a70;
$saas-success-background: #effaf5;

$saas-table-striped-bg-color: $saas-background-color;
$saas-datagrid-font-size: .875rem;
$saas-datagrid-header-font-weight: 600;
$saas-datagrid-font-weight: 400;
$saas-datagrid-font-color: $saas-dark-gray;
$saas-datagrid-highlight-background: $saas-light-gray;
$saas-badge-green-border: #60c99d;
$saas-badge-green-background: $saas-success-background;
$saas-badge-yellow-border: #ffca7a;
$saas-badge-yellow-background: #fffaf2;
$saas-badge-red-border: #cc5252;
$saas-badge-red-background: #faeeee;
$saas-badge-draft-border: $saas-light-gray-border;
$saas-badge-draft-background: $saas-background-color;
$saas-select-item-hover-background: #e3e7eb;

$saas-multiselect-hover-border: #93a0af;

$font-family-sans-serif: "Inter", -apple-system, "BlinkMacSystemFont",
"Segoe UI", "Helvetica Neue", "Arial", "Noto Sans", sans-serif,
"Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-monospace: "SFMono-Regular", "Menlo", "Monaco", "Consolas",
"Liberation Mono", "Courier New", monospace;

$font-size-base: 1rem;
$font-size-sm: $font-size-base * 0.875;
$font-size-xs: $font-size-base * 0.65;
$font-size-bg: $font-size-base * 1.25;
$font-size-xg: $font-size-base * 1.5;

$saas-input-padding: 0.5rem 0.75rem;
$inputPadding: .3rem .3rem;
$inlineSpacing: .5rem;

//#31383F nav bg
//#494F57 nav item bg

$blue: #4872ac;
$sapphire: #5a94d8 !default;
$indigo: #6610f2;
$purple: #6f42c1;
$rose: #bd5091 !default;
$pink: #ef5675;
$red: #e55c5c;
$orange: #ff764a;
$yellow: #ffbd59;
$green: #96c670;
$teal: #20c997;
$cyan: #a2c7e9;
$benchmarks-blue: #3e70b5;

// scss-docs-start colors-map
$colors: (
        "blue": $blue,
        "sapphire": $sapphire,
        "indigo": $indigo,
        "purple": $purple,
        "rose": $rose,
        "pink": $pink,
        "red": $red,
        "orange": $orange,
        "yellow": $yellow,
        "green": $green,
        "teal": $teal,
        "cyan": $cyan,
        "white": $white,
        "gray": $gray-600,
        "gray-dark": $gray-800,
);

$primary: $orange;
$secondary: $blue;
$success: $green-600;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-300;
$dark: $gray-800;

$status-colors: (
        success: $saas-badge-green-border,
        success-accent: $saas-badge-green-background,
        warning: $saas-badge-yellow-border,
        warning-accent: $saas-badge-yellow-background,
        danger: $saas-badge-red-border,
        danger-accent: $saas-badge-red-background,
        muted: $saas-badge-draft-border,
        muted-accent: $saas-badge-draft-background,
        secondary: theme("colors.blue-300"),
        secondary-accent: theme("colors.blue-100")
);

$pill-colors: (
        success: $saas-badge-green-border,
        success-accent: $saas-badge-green-background,
        warning: $saas-badge-yellow-border,
        warning-accent: $saas-badge-yellow-background,
        danger: $saas-badge-red-border,
        danger-accent: $saas-badge-red-background,
        muted: $gray-300,
        muted-accent: $gray-400
);

// navbar
$navbar-height: 3rem;

// footer
$footer-padding-y: 0.8rem;
$footer-padding-x: 0;
$footer-font-size: 0.8rem;
$footer-line-height: 1;
// Calculate the footer height based on the font size, line height, and padding
$footer-height: ($footer-font-size * $footer-line-height) + (2 * $footer-padding-y);
