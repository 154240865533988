@tailwind components;
@tailwind utilities;
.m-divider {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid theme("colors.gray-400");
}
.m-divider.dark {
  border-top: 1px solid theme("colors.gray-900");
}
.m-divider.two-tone {
  border-top: 1px solid theme("colors.gray-300");
  border-bottom: 2px solid theme("colors.gray-000");
}

.m-divider.vertical {
  display: block;
  height: inherit;
  border: 0;
  border-right: 1px solid theme("colors.gray-400");
}
.m-divider.vertical.dark {
  border-right: 1px solid theme("colors.gray-900");
}
.m-divider.vertical.two-tone {
  border-right: 1px solid theme("colors.gray-300");
  border-left: 2px solid theme("colors.gray-000");
}

.m-tooltip {
  max-width: initial !important;
  padding: 0 0.375rem !important;
  text-align: center;
}
.m-tooltip .p-tooltip-text {
  @apply b1 text-gray-000;
  background-color: theme("colors.gray-1000") !important;
  border-radius: 4px;
  min-height: 3rem !important;
  padding: 1rem 1.5rem !important;
  white-space: normal !important;
}
.m-tooltip.p-tooltip-right .p-tooltip-arrow {
  border-width: 0.375rem 0.375rem 0.375rem 0 !important;
  border-color: transparent theme("colors.gray-1000") transparent transparent !important;
  top: calc(50% - 0.175rem) !important;
}
.m-tooltip.p-tooltip-top .p-tooltip-arrow {
  border-width: 0.375rem 0.375rem 0 !important;
  border-color: theme("colors.gray-1000") transparent transparent transparent !important;
  top: 100% !important;
  left: calc(50% - 0.375rem) !important;
}
.m-tooltip.p-tooltip-top.left .p-tooltip-arrow, .m-tooltip.p-tooltip-bottom.left .p-tooltip-arrow {
  left: calc(20% - 0.375rem) !important;
}
.m-tooltip.p-tooltip-top.right .p-tooltip-arrow, .m-tooltip.p-tooltip-bottom.right .p-tooltip-arrow {
  left: calc(80% - 0.375rem) !important;
}
.m-tooltip.p-tooltip-bottom .p-tooltip-arrow {
  border-width: 0 0.375rem 0.375rem !important;
  border-color: transparent transparent theme("colors.gray-1000") transparent !important;
  top: -0.375rem !important;
  left: calc(50% - 0.375rem) !important;
}
.m-tooltip.p-tooltip-left .p-tooltip-arrow {
  border-width: 0.375rem 0 0.375rem 0.375rem !important;
  border-color: transparent transparent transparent theme("colors.gray-1000") !important;
  top: calc(50% - 0.175rem) !important;
}

.highcharts-tooltip {
  background-color: theme("colors.gray-1000") !important;
}
.highcharts-tooltip .highcharts-label-box.highcharts-tooltip-box {
  background-color: theme("colors.gray-1000") !important;
  fill: theme("colors.gray-1000") !important;
  stroke: theme("colors.gray-1000") !important;
  stroke-width: 1 !important;
}

.tooltip-body {
  background-color: theme("colors.gray-1000");
  min-height: 3rem !important;
  min-width: 10.9375rem !important;
  padding: 0.5rem 1rem !important;
}

.m-tabview .p-tabview .p-tabview-nav {
  border-bottom: 0;
  gap: 2rem;
}
.m-tabview .p-tabview .p-tabview-nav li {
  margin-right: 0;
}
.m-tabview .p-tabview .p-tabview-nav li:not(.p-tabview-ink-bar) {
  display: flex;
}
.m-tabview .p-tabview .p-tabview-nav li .p-highlight {
  background: transparent;
  border-bottom-color: theme("colors.orange-300");
}
.m-tabview .p-tabview .p-tabview-nav li:hover .p-tabview-nav-link:not([aria-selected=true]) {
  color: theme("colors.gray-800") !important;
  background: transparent !important;
  border: none !important;
  border-bottom: 2px solid theme("colors.gray-400") !important;
}
.m-tabview .p-tabview .p-tabview-nav li .p-tabview-nav-link {
  color: theme("colors.gray-800");
  background: transparent;
  border: none;
  border-bottom: 2px solid transparent;
  margin: 0;
  padding: 0;
  padding-bottom: 0.5rem;
}
.m-tabview .p-tabview .p-tabview-nav li .p-tabview-nav-link .saas-tab-header {
  color: theme("colors.gray-800");
}
.m-tabview .p-tabview .p-tabview-nav li .p-tabview-nav-link[aria-selected=true] {
  color: theme("colors.gray-1000");
  border-bottom-color: theme("colors.orange-300");
}
.m-tabview .p-tabview .p-tabview-nav li .p-tabview-nav-link[aria-selected=true] .saas-tab-header {
  color: theme("colors.gray-1000");
}
.m-tabview .p-tabview .p-tabview-nav li .p-tabview-nav-link:focus {
  box-shadow: none !important;
}
.m-tabview .p-tabview .p-tabview-panels {
  padding: 0;
  margin-top: 1rem;
}
.m-tabview .p-tabview .p-tabview-panels .p-tabview-panel {
  padding: 0;
}
.m-tabview.contained .m-tabview-header {
  background-color: theme("colors.gray-100");
  border: 1px solid theme("colors.gray-500");
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: none;
}
.m-tabview.contained .p-tabview-panels {
  background-color: theme("colors.gray-000");
  border: 0 none;
  margin: 2rem;
}
.m-tabview.contained .p-tabview .p-tabview-nav {
  border-bottom: 1px solid theme("colors.gray-500");
  grid-gap: 10px;
  padding-left: 25px;
}
.m-tabview.contained .p-tabview .p-tabview-nav li .p-tabview-nav-link {
  padding: 0;
  border: none;
}
.m-tabview.contained .p-tabview .p-tabview-nav li .p-tabview-nav-link:hover {
  border: none !important;
}
.m-tabview.contained .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  box-shadow: none;
}
.m-tabview.contained .p-tabview .p-highlight .p-tabview-nav-link {
  border-bottom: 1px solid theme("colors.gray-000");
  margin-bottom: -1px;
}
.m-tabview.contained .p-tabview .p-highlight .m-tabview-header {
  background-color: theme("colors.gray-000");
}

.m-table .p-datatable-wrapper {
  transition: max-height ease-in-out 300ms;
}
.m-table .p-datatable-wrapper .p-datatable-table {
  border-collapse: separate;
}
.m-table .p-datatable-wrapper .p-datatable-table .p-datatable-thead tr,
.m-table .p-datatable-wrapper .p-datatable-table .p-datatable-tfoot tr {
  border: 0 none;
}
.m-table .p-datatable-wrapper .p-datatable-table .p-datatable-thead th,
.m-table .p-datatable-wrapper .p-datatable-table .p-datatable-thead td,
.m-table .p-datatable-wrapper .p-datatable-table .p-datatable-tfoot th,
.m-table .p-datatable-wrapper .p-datatable-table .p-datatable-tfoot td {
  @apply b3 bg-gray-100 text-gray-1000;
  border: 0 none;
  border-width: 0;
  padding: 1rem;
  border-bottom: 1px solid theme("colors.orange-300");
}
.m-table .p-datatable-wrapper .p-datatable-table .p-datatable-tbody tr.disabled-row td {
  background: theme("colors.gray-100") !important;
}
.m-table .p-datatable-wrapper .p-datatable-table .p-datatable-tbody td {
  @apply b1 bg-gray-000 text-gray-1000;
  border: 0 none;
  border-bottom: 1px solid theme("colors.gray-300");
  padding: 1rem;
}
.m-table .p-datatable-wrapper .p-datatable-table .p-datatable-tbody td:has(.bg-yellow-100) {
  background: theme("colors.yellow-100") !important;
}
.m-table .p-datatable-wrapper .p-datatable-table .p-datatable-thead .header-group th {
  @apply bg-gray-000 text-gray-1000;
  border: 0 none;
}
.m-table .p-datatable-wrapper .p-datatable-table .p-datatable-thead th[aria-sort=descending] .p-element {
  opacity: 1;
}
.m-table .p-datatable-wrapper .p-datatable-table .p-datatable-thead th[aria-sort=ascending] .p-element {
  opacity: 1;
}
.m-table .p-datatable-wrapper .p-datatable-table .p-datatable-thead th[aria-sort=ascending] .p-element .p-icon-wrapper:before {
  transform: rotate(180deg) scaleX(-1);
}
.m-table .p-datatable-wrapper .p-datatable-table .p-datatable-thead .p-icon-wrapper {
  opacity: 0;
  transition: opacity 0.2s;
}
.m-table .p-datatable-wrapper .p-datatable-table .p-datatable-thead .p-icon-wrapper:before {
  @apply icon-small text-gray-500 ml-2;
  content: "sort";
  display: inline-block;
}
.m-table .p-datatable-wrapper .p-datatable-table .p-datatable-thead .p-icon-wrapper:hover:before {
  @apply text-blue-500;
}
.m-table .p-datatable-wrapper .p-datatable-table .p-datatable-thead .p-icon-wrapper svg {
  display: none;
}
.m-table .p-datatable-wrapper .p-datatable-table .p-datatable-thead .p-checkbox-box.p-highlight .p-icon-wrapper {
  opacity: 1;
}
.m-table .p-datatable-wrapper .p-datatable-table .p-datatable-thead .p-checkbox-box.p-highlight .p-icon-wrapper:before {
  content: none;
}
.m-table .p-datatable-wrapper .p-datatable-table .p-datatable-thead .p-checkbox-box.p-highlight .p-icon-wrapper svg {
  display: block;
}
.m-table .p-datatable-wrapper .p-datatable-table .p-datatable-thead th:hover .p-icon-wrapper {
  opacity: 1;
}
.m-table .p-datatable-wrapper .p-datatable-table .p-datatable-tfoot td {
  border-bottom: 0 none;
}
.m-table .p-datatable-wrapper .p-datatable-table .p-paginator-bottom.p-paginator.p-component {
  padding-right: unset;
  padding-left: unset;
}
.m-table .p-datatable-wrapper .p-datatable-table .p-checkbox-box.p-highlight {
  background-color: theme("colors.gray-000") !important;
}
.m-table .p-paginator {
  @apply bg-gray-000 border-0;
  display: flex;
  gap: 0.5rem;
  height: 5rem;
}
.m-table .p-paginator .p-paginator-current {
  @apply b1;
  border: none;
  color: theme("colors.gray-800");
}
.m-table .p-paginator .p-paginator-next,
.m-table .p-paginator .p-paginator-prev,
.m-table .p-paginator .p-paginator-first,
.m-table .p-paginator .p-paginator-last {
  background: none;
  border: 2px transparent;
  border-radius: 1.25rem;
  height: 2.5rem;
  min-width: 2.5rem;
}
.m-table .p-paginator .p-paginator-next.p-link:focus,
.m-table .p-paginator .p-paginator-prev.p-link:focus,
.m-table .p-paginator .p-paginator-first.p-link:focus,
.m-table .p-paginator .p-paginator-last.p-link:focus {
  box-shadow: none;
}
.m-table .p-paginator .p-paginator-next:hover,
.m-table .p-paginator .p-paginator-prev:hover,
.m-table .p-paginator .p-paginator-first:hover,
.m-table .p-paginator .p-paginator-last:hover {
  border: 2px solid theme("colors.blue-600");
  background: none;
}
.m-table .p-paginator .p-paginator-pages {
  display: flex;
  gap: 0.5rem;
}
.m-table .p-paginator .p-paginator-pages .p-paginator-page {
  @apply b1;
  background: none;
  border: 2px transparent;
  border-radius: 1.25rem;
  height: 2.5rem;
  min-width: 2.5rem;
  color: theme("colors.gray-1000");
}
.m-table .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  border: 2px solid theme("colors.orange-300");
  color: theme("colors.gray-1000");
}
.m-table .p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
  border: 2px solid theme("colors.blue-600");
}
.m-table .p-paginator .p-paginator-pages .p-paginator-page.p-link:focus {
  box-shadow: none;
}

.m-input.p-inputgroup {
  border-color: theme("colors.gray-500");
}
.m-input.p-inputgroup .p-inputgroup-addon {
  background: theme("colors.gray-000");
  color: theme("colors.blue-600");
  border-color: theme("colors.gray-500");
}
.m-input.p-inputtext {
  box-shadow: inset 0 0 0 1px theme("colors.gray-500");
  border: none;
  border-radius: 0.25rem;
  height: 2rem;
  width: inherit;
  padding: 0.5rem 0.75rem;
  color: theme("colors.gray-1000");
}
.m-input.p-inputtext:hover {
  background: theme("colors.gray-000");
  box-shadow: inset 0 0 0 1px theme("colors.gray-500");
  border: none;
}
.m-input.p-inputtext:focus {
  box-shadow: inset 0 0 0 2px theme("colors.blue-600");
  border: none;
}
.m-input.ng-invalid.ng-touched {
  background: theme("colors.red-100");
  box-shadow: inset 0 0 0 2px theme("colors.red-700");
  border: none;
  border-radius: 0.25rem;
}
.m-input::placeholder {
  @apply b1 text-gray-600;
}
.m-input textarea.p-inputtextarea {
  box-shadow: inset 0 0 0 2px theme("colors.gray-500");
  border: none;
  border-radius: 0.25rem;
  min-height: 2rem;
  width: inherit;
}
.m-input textarea.p-inputtextarea:focus {
  box-shadow: inset 0 0 0 2px theme("colors.blue-600");
  border: none;
}
.m-input textarea.ng-invalid.ng-touched {
  background: theme("colors.red-100");
  box-shadow: inset 0 0 0 2px theme("colors.red-700");
  border: none;
  border-radius: 0.25rem;
}
.m-input small.p-error {
  @apply c1 font-medium;
  color: theme("colors.red-700");
}
.m-input small.p-success {
  @apply c1 font-medium;
  color: theme("colors.green-700");
}
.m-input.input-search.p-inputtext {
  padding: 0.5rem 3.5rem 0.5rem 0.75rem;
}

.icon-filled {
  font-variation-settings: "FILL" 1;
}