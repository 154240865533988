/* Add global styles to this file, import other style files */
@import "assets/bootstrap_overrides";
@import "node_modules/bootstrap/scss/bootstrap";
@import "assets/icons/icons";
@import "primeicons/primeicons.css";
@import "libs/common-styles/saas-variables";
@import "libs/design-system/src/lib/styles/global";
@import "assets/icons/brand-icons";
@import "libs/common-animations/keyframes";

html {
  min-width: 1280px;
}

:root {
  --navbar-height: 3rem;
  --footer-height: 2.5rem;
}

/* Bootstrap-defined styles should be overridden below */
body {
  padding: 0;
  margin: 0;
  // keep lastpass from showing up
  > div[id^="__lpform_input"] {
    // stylelint-disable declaration-no-important
    display: none !important;
    visibility: hidden !important;
    // stylelint-enable declaration-no-important
  }

  a {
    color: $primary;
    text-decoration: none;
  }

  ol,
  ul,
  dl {
    padding: 0;
    margin: 0;
  }

  .btn-xs {
    padding: .05rem .5rem;
    font-size: .8rem;
    border-radius: .2rem;
  }
}

.act-as-link {
  cursor: pointer;
}

h1 {
  font-weight: 200;
}

.fw-boldish {
  font-weight: $fw-boldish;
}

.fs-small {
  font-size: $font-size-sm;
}

.fs-xsmall {
  font-size: $font-size-xs;
}

.full-page-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 4rem);
}

.previous-period-value {
  font-weight: 600;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: left center;

  &.up {
    color: $green-700;
  }

  &.down {
    color: $red;
  }

  &.zero {
    background-image: none;

    .pi {
      display: none;
    }
  }

  .pi {
    font-size: .75rem;
  }
}
