@keyframes slideInUp {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes slideOutDown {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(100%);
  }
}
