.m-table {
  // Target the PrimeNG datatable component
  .p-datatable-wrapper {
    transition: max-height ease-in-out 300ms;

    .p-datatable-table {
      border-collapse: separate; // Ensure borders don't collapse due to tailwind reboot
      // Style the table headers and footers
      .p-datatable-thead,
      .p-datatable-tfoot {
        tr {
          border: 0 none;
        }

        th,
        td {
          @apply b3 bg-gray-100 text-gray-1000;
          // display: table-cell !important;
          border: 0 none;
          border-width: 0;
          padding: 1rem;
          border-bottom: 1px solid theme('colors.orange-300');
        }
      }

      // Style the table body
      .p-datatable-tbody {
        tr {
          &.disabled-row {
            td {
              background: theme('colors.gray-100') !important;
            }
          }
        }
        td {
          @apply b1 bg-gray-000 text-gray-1000;
          border: 0 none;
          border-bottom: 1px solid theme('colors.gray-300');
          padding: 1rem;

          &:has(.bg-yellow-100) {
            background: theme('colors.yellow-100') !important;
          }
        }
      }

      // Style the header group rows
      .p-datatable-thead {
        .header-group th {
          @apply bg-gray-000 text-gray-1000;
          border: 0 none;
        }

        // When sorting direction is active, sort icon should be visible
        th[aria-sort='descending'] {
          .p-element {
            opacity: 1;
          }
        }

        // When sorting direction is active, sort icon should be visible and rotated
        th[aria-sort='ascending'] {
          .p-element {
            opacity: 1;
            .p-icon-wrapper {
              &:before {
                transform: rotate(180deg) scaleX(-1);
              }
            }
          }
        }

        .p-icon-wrapper {
          opacity: 0;
          transition: opacity 0.2s;

          &:before {
            @apply icon-small text-gray-500 ml-2;
            content: 'sort';
            display: inline-block;
          }

          &:hover:before {
            @apply text-blue-500;
          }
          svg {
            display: none;
          }
        }

        // Override styles when the parent has both classes
        .p-checkbox-box.p-highlight .p-icon-wrapper {
          opacity: 1;

          &:before {
            content: none;
          }

          svg {
            display: block;
          }
        }

        th:hover .p-icon-wrapper {
          opacity: 1;
        }
      }

      // Style the header group rows
      .p-datatable-tfoot {
        td {
          border-bottom: 0 none;
        }
      }

      .p-paginator-bottom.p-paginator.p-component {
        padding-right: unset;
        padding-left: unset;
      }

      // Override styles when the parent has both classes
      .p-checkbox-box.p-highlight {
        background-color: theme('colors.gray-000') !important;
      }
    }
  }

  .p-paginator {
    @apply bg-gray-000 border-0;
    display: flex;
    gap: 0.5rem;
    height: 5rem;

    .p-paginator-current {
      @apply b1;
      border: none;
      color: theme('colors.gray-800');
    }

    .p-paginator-next,
    .p-paginator-prev,
    .p-paginator-first,
    .p-paginator-last {
      background: none;
      border: 2px transparent;
      border-radius: 1.25rem;
      height: 2.5rem;
      min-width: 2.5rem;
    }

    .p-paginator-next.p-link:focus,
    .p-paginator-prev.p-link:focus,
    .p-paginator-first.p-link:focus,
    .p-paginator-last.p-link:focus {
      box-shadow: none;
    }

    .p-paginator-next:hover,
    .p-paginator-prev:hover,
    .p-paginator-first:hover,
    .p-paginator-last:hover {
      border: 2px solid theme('colors.blue-600');
      background: none;
    }

    .p-paginator-pages {
      display: flex;
      gap: 0.5rem;

      .p-paginator-page {
        @apply b1;
        background: none;
        border: 2px transparent;
        border-radius: 1.25rem;
        height: 2.5rem;
        min-width: 2.5rem;
        color: theme('colors.gray-1000');
      }

      .p-paginator-page.p-highlight {
        border: 2px solid theme('colors.orange-300');
        color: theme('colors.gray-1000');
      }

      .p-paginator-page:not(.p-highlight):hover {
        border: 2px solid theme('colors.blue-600');
      }

      .p-paginator-page.p-link:focus {
        box-shadow: none;
      }
    }
  }
}
