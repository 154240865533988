$icon-bg-color: #858585 !default;

.icon {
  background-color: $icon-bg-color;
  background-repeat: no-repeat;
  background-position: center;

  &.no-bg {
    background-color: transparent;
  }

  &.xs {
    display: block;
    width: 1.2rem;
    height: 1.2rem;
  }

  &.sm {
    display: block;
    width: 30px;
    height: 30px;
  }

  &.md {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }

  &.invert {
    filter: invert(100%);
  }

  &.text-inline {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    vertical-align: text-bottom;
  }

  &.round {
    border-radius: 4rem;
  }

  &.icon-mdw-reports {
    background-image: url("database.svg");
  }

  &.icon-cross-channel {
    background-image: url("cross-channel.svg");
  }

  &.icon-cross-channel-roas-weekly,
  &.icon-cross-channel-cpo-weekly,
  &.icon-cross-channel-weekly {
    background-image: url("cross-channel-weekly.svg");
  }

  &.icon-adwords {
    background-image: url("adwords.svg");
  }

  &.icon-customer-reporting {
    background-image: url("customer-reporting.svg");
  }

  &.icon-facebook,
  &.icon-facebook-scale-report,
  &.icon-facebook-scale-config {
    background-image: url("facebook.svg");
  }

  &.icon-geo {
    background-image: url("geo.svg");
  }

  &.icon-list,
  &.icon-catalog {
    background-image: url("list.svg");
  }

  &.icon-ltv {
    background-image: url("ltv.svg");
  }

  &.icon-retargeting {
    background-image: url("retargeting.svg");
  }

  &.icon-geo-doe {
    background-image: url("map-marker.svg");
  }

  &.icon-tv-attribution,
  &.icon-roku {
    background-image: url("tv-attribution.svg");
  }

  &.icon-weekly-report {
    background-image: url("weekly-report.svg");
  }

  &.icon-facebook-roas-weekly-report,
  &.icon-facebook-cpo-weekly-report,
  &.icon-facebook-weekly {
    background-image: url("facebook-weekly.svg");
  }

  &.icon-custom-dashboard {
    background-image: url("custom-dashboard.svg");
  }

  &.icon-cog {
    background-image: url("cog.svg");
  }

  &.icon-brands {
    background-image: url("brands.svg");
  }

  &.icon-products {
    background-image: url("products.svg");
  }

  &.icon-logout {
    background-image: url("logout.svg");
  }

  &.icon-chevron-left {
    background-image: url("chevron-last-left.svg");
  }

  &.icon-chevron-right {
    background-image: url("chevron-last-right.svg");
  }

  &.icon-toggle {
    background-image: url("toggle.svg");
  }

  &.icon-external-link {
    background-image: url("external-link.svg");
  }

  &.icon-client-onboarding {
    background-image: url("client-onboarding.svg");
  }

  &.icon-support {
    background-image: url("support-headset.svg");
  }

  &.icon-trash {
    background-image: url("trash.svg");
  }

  &.icon-arrow-up {
    background-image: url("arrow-up.svg");
  }

  &.icon-arrow-down {
    background-image: url("arrow-down.svg");
  }
}

.custom-icon {
  &::before {
    display: block;
    width: 1.3rem;
    height: 1rem;
    content: "";
    filter: invert(100%);
    background-repeat: no-repeat;
    background-position: top left;
    background-size: contain;
  }

  &.pi-decisioning::before {
    background-image: url("decisioning.svg");
  }

  &.pi-experiments::before {
    background-image: url("experiments.svg");
  }

  &.pi-mdw::before {
    background-image: url("mdw.svg");
  }

  &.pi-integrations::before {
    background-image: url("integrations.svg");
  }
}
