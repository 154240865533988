.m-divider {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid theme('colors.gray-400');

  &.dark {
    border-top: 1px solid theme('colors.gray-900');
  }

  &.two-tone {
    border-top: 1px solid theme('colors.gray-300');
    border-bottom: 2px solid theme('colors.gray-000');
  }
}

.m-divider.vertical {
  display: block;
  height: inherit;
  border: 0;
  border-right: 1px solid theme('colors.gray-400');

  &.dark {
    border-right: 1px solid theme('colors.gray-900');
  }

  &.two-tone {
    border-right: 1px solid theme('colors.gray-300');
    border-left: 2px solid theme('colors.gray-000');
  }
}
